"use client";

import { createContext, FC, PropsWithChildren, useReducer } from "react";
import { TableAction, TableActionType, TableContextModel, TableState } from "~/types/table.types";

const defaultState: TableState = {
    pagination: {
        page: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
    },
    searchQuery: "",
    selectedItems: [],
    sorting: [],
    hiddenColumns: [],
};

const reducer = (state: TableState, action: TableAction): TableState => {
    switch (action.type) {
        case TableActionType.RESET: {
            return defaultState;
        }

        case TableActionType.SET_PAGINATION: {
            return { ...state, pagination: action.payload };
        }

        case TableActionType.SET_PAGE: {
            return { ...state, pagination: { ...state.pagination, page: action.payload } };
        }

        case TableActionType.SET_SEARCH_QUERY: {
            return { ...state, searchQuery: action.payload };
        }

        case TableActionType.SET_SELECTED_ITEMS: {
            return { ...state, selectedItems: action.payload };
        }

        case TableActionType.SET_SORTING: {
            return { ...state, sorting: action.payload };
        }

        case TableActionType.SET_COLUMN_VISIBILITY: {
            const isColumnHidden = state.hiddenColumns.includes(action.payload);

            return {
                ...state,
                hiddenColumns: isColumnHidden
                    ? state.hiddenColumns.filter((column) => column !== action.payload)
                    : [...state.hiddenColumns, action.payload],
            };
        }

        default: {
            return state;
        }
    }
};

export const TableContext = createContext({} as TableContextModel);

export const TableContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return <TableContext.Provider value={{ state, dispatch }}>{children}</TableContext.Provider>;
};
