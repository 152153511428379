import { gql, useLazyQuery } from "@apollo/client";
import { signOut, useSession } from "next-auth/react";
import { useContext, useEffect } from "react";
import { setUserData } from "~/actions/user.actions";
import { defaultState, UserContext } from "~/context/User.context";
import { UsersPermissionsUserEntityResponse } from "~/generated-types";
import { getImageUrl } from "~/utils/strapi.utils";

const GET_USER = gql`
    query GetUser($id: ID!) {
        usersPermissionsUser(id: $id) {
            data {
                id
                attributes {
                    admin
                    avatar {
                        data {
                            id
                            attributes {
                                name
                                formats
                            }
                        }
                    }
                    collapsed_sidebar
                    customer {
                        data {
                            id
                        }
                    }
                    first_name
                    last_name
                    phone
                    phone_prefix
                    email
                    locale
                    user_group {
                        data {
                            id
                            attributes {
                                modules {
                                    id
                                    name
                                }
                                permissions {
                                    id
                                    object
                                    create
                                    read
                                    update
                                    delete
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const useCurrentUserData = () => {
    const session = useSession();
    const { state, dispatch } = useContext(UserContext);
    const loading = session.status === "loading" || Object.is(state.data, defaultState.data);
    const [getUser, { data, error }] = useLazyQuery<
        { usersPermissionsUser: UsersPermissionsUserEntityResponse },
        { id: string | number }
    >(GET_USER);

    useEffect(() => {
        if (session.status === "loading" || !loading) return;

        if (session.status !== "authenticated") {
            dispatch(setUserData(defaultState.data));
            return;
        }

        getUser({ variables: { id: session.data.id } });
    }, [session, getUser, dispatch, loading]);

    useEffect(() => {
        // @ts-ignore
        const networkStatusCode = error?.networkError?.statusCode;

        if (!networkStatusCode) return;

        if (networkStatusCode === 401) {
            signOut().then(() => {
                dispatch(setUserData(defaultState.data));
            });
        }
    }, [dispatch, error]);

    useEffect(() => {
        if (!data?.usersPermissionsUser.data?.id || !data?.usersPermissionsUser.data?.attributes) return;

        const {
            admin,
            avatar,
            collapsed_sidebar,
            customer,
            first_name,
            last_name,
            phone,
            phone_prefix,
            locale,
            user_group,
            email,
            simulate_customer_id,
        } = data.usersPermissionsUser.data.attributes;

        const avatarThumbnailUrl = avatar?.data?.[0]?.attributes?.formats?.thumbnail?.url ?? null;

        dispatch(
            setUserData({
                admin: admin ?? false,
                avatar: avatarThumbnailUrl
                    ? {
                          name: avatar?.data?.[0]?.attributes?.name ?? "",
                          url: getImageUrl(avatar?.data?.[0], "thumbnail") ?? "",
                      }
                    : undefined,
                collapsedSidebar: collapsed_sidebar ?? false,
                customerId: customer?.data?.id ?? 0,
                firstName: first_name ?? "",
                lastName: last_name ?? "",
                phone: phone ?? "",
                phone_prefix: phone_prefix ?? "",
                email: email ?? "",
                id: data.usersPermissionsUser.data.id,
                locale: locale ?? "da",
                modules: user_group?.data?.attributes?.modules?.map(({ name }) => name) ?? [],
                permissions: user_group?.data?.attributes?.permissions ?? [],
                simulate_customer_id,
            }),
        );
    }, [data, dispatch]);

    return { data: state.data, loading, error };
};

export default useCurrentUserData;
