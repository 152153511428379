"use client";

import { createContext, FC, PropsWithChildren, useReducer } from "react";
import { UserAction, UserActionType, UserContextModel, UserState } from "~/types/user.types";

export const defaultState: UserState = {
    data: {
        admin: false,
        collapsedSidebar: false,
        customerId: 0,
        firstName: "",
        lastName: "",
        phone: "",
        phone_prefix: "",
        email: "",
        id: 0,
        locale: "da",
        modules: [],
        permissions: [],
        simulate_customer_id: undefined,
    },
};

const reducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case UserActionType.SET_DATA: {
            return { data: action.payload };
        }

        default: {
            return state;
        }
    }
};

export const UserContext = createContext({} as UserContextModel);

export const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};
