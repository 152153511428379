import { Dispatch } from "react";
import { ComponentAccessPermission, UserGroup } from "~/generated-types";
import { AccessModule, ID } from "./general.types";

export interface UserDataInterface {
    admin: boolean;
    avatar?: {
        name: string;
        url: string;
    };
    collapsedSidebar: boolean;
    customerId: ID;
    firstName: string;
    lastName: string;
    phone: string;
    phone_prefix: string;
    email: string;
    id: ID;
    locale: string;
    modules: AccessModule[];
    permissions: ComponentAccessPermission[];
    simulate_customer_id?: number;
}

export interface AvatarResponse {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: ImageFormatsList;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: string | null;
    createdAt: string;
    updatedAt: string;
}

export interface UserDataResponse {
    admin: boolean;
    avatar?: AvatarResponse[];
    collapsed_sidebar: boolean;
    customer?: { id: number }; // create full Customer type
    first_name: string;
    last_name: string;
    phone: string;
    phone_prefix: string;
    email: string;
    id: number;
    locale: string;
    modules: AccessModule[];
    permissions: ComponentAccessPermission[];
    user_group?: UserGroup;
    simulate_customer_id?: number;
}

export interface UserState {
    data: UserDataInterface;
}

export interface UserContextModel {
    state: UserState;
    dispatch: Dispatch<any>;
}

export interface UserRoleWithCustomer {
    id: number;
    admin: boolean;
    customer: { id: number };
}

export interface UserCustomerId {
    id: ID;
    admin: boolean;
    customer: {
        id: ID;
    };
}

export enum UserActionType {
    SET_DATA,
}

export enum UserQueryKeys {
    USER = "user",
    USER_CUSTOMER_ID = "user_customer_id",
}

export type UserAction = { type: UserActionType.SET_DATA; payload: UserDataInterface };
