import React from "react";
import Spinner from "@ui/Spinner";

export const LogoLoader = () => (
    <div className="h-full w-full place-items-center bg-white">
        <img
            alt="SporingsGruppen Logo"
            src="/logo-square-color.svg"
            className="absolute left-1/2 top-1/2 h-9 w-9 -translate-x-1/2 -translate-y-1/2 rounded-md"
        />
        <Spinner size="large" variant="dark" />
    </div>
);
