import { FC } from "react";
import { cn } from "~/utils/lib.utils";

interface SpinnerProps {
    size?: "default" | "large" | "small" | "medium";
    variant?: "dark" | "light";
    className?: string;
    withContainer?: boolean;
}

const sizeMap = {
    large: 20,
    default: 16,
    medium: 8,
    small: 4,
};

const borderMap = {
    default: 8,
    large: 8,
    medium: 4,
    small: 2,
};

const Spinner: FC<SpinnerProps> = ({ size = "default", variant = "dark", withContainer = true, className }) => {
    const inner = (
        <div
            className={cn(
                `animate-spin w-${sizeMap[size]} h-${sizeMap[size]} border-${borderMap[size]} rounded-full`,
                variant === "dark" && "border-primary border-b-transparent",
                variant === "light" && "border-white border-b-transparent",
                className,
            )}
        />
    );

    if (!withContainer) {
        return inner;
    }

    return <div className="grid h-full w-full place-items-center">{inner}</div>;
};

export default Spinner;
