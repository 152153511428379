import { useContext, useEffect, useState } from "react";
import { UserContext } from "~/context/User.context";
import { AccessModule } from "~/types/general.types";

const alwaysAllowedModules = [AccessModule.Dashboard, AccessModule.Settings, AccessModule.Profile];

const useViewModuleAccess = (module?: AccessModule | null) => {
    const { state } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (!state.data.id) return;

        if (!module || state.data.admin || alwaysAllowedModules.includes(module)) {
            setLoading(false);
            setAllowed(true);
            return;
        }

        setAllowed(!!state.data.modules.find((accessModule) => accessModule === module));
        setLoading(false);
    }, [module, state.data.modules, state.data.admin, state.data.id]);

    return { loading, allowed };
};

export default useViewModuleAccess;
