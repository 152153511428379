import { DocumentNode } from "@apollo/client";
import { Dispatch, MouseEvent, ReactNode } from "react";
import { Pagination } from "~/generated-types";
import { GenericObject, ID } from "./general.types";
import { Action } from "@ui/Table";
import { buttonVariants } from "@ui/button";
import { VariantProps } from "class-variance-authority";

export interface BulkAction extends Omit<Action, "condition"> {
    action: (ids: ID[]) => void | Promise<void>;
    variant?: VariantProps<typeof buttonVariants>["variant"];
}

export interface NestedSortable {
    fieldName: string;
}

export type TableCellFunction<T> = ({
    value,
    row,
    index,
}: {
    value: any;
    row: T;
    index: number;
}) => JSX.Element | string;

export interface TableColumn<T = any> {
    // Key of the attributes to dynamically fetch the value from
    accessor: keyof T;
    // Protect certain table column from hiding by user
    isHidingDisabled?: boolean;
    // Custom function to create special cell contents
    cell?: TableCellFunction<TableRowData<T>>;
    // Heading of the column in the table header
    heading?: string;
    // Is column searchable
    searchable?: boolean;
    // Is column sortable
    sortable?: boolean | NestedSortable;
    // Toggle if the column should be displayed on mobile devices
    visibleOnMobile?: boolean;
}

export interface TableRowData<T> {
    id: ID;
    attributes: T;
}

export type SortString = `${string}:asc` | `${string}:desc`;
export type SortArg = SortString | SortString[];

export interface TableProps {
    additionalSearchFields?: string[];
    additionalFilters?: GenericObject;
    additionalFilterElements?: ReactNode | null;
    bulkActions?: BulkAction[];
    collectionKey: string;
    columns: TableColumn[];
    customerId?: ID | null;
    customerFilterFallback?: GenericObject;
    defaultPageSize?: number;
    defaultSort: SortArg;
    disableCustomerFilter?: boolean;
    primaryRowAction?: <T = any>(data: TableRowData<T>, event: MouseEvent<HTMLTableRowElement>) => Promise<void> | void;
    query: DocumentNode;
    queryVariables?: GenericObject;
    rowActions?: Action[];
}

export interface TableState {
    pagination: Pagination;
    searchQuery: string;
    selectedItems: ID[];
    sorting: SortArg;
    hiddenColumns: string[];
}

export interface TableContextModel {
    state: TableState;
    dispatch: Dispatch<any>;
}

export enum TableActionType {
    RESET,
    SET_PAGINATION,
    SET_PAGE,
    SET_SEARCH_QUERY,
    SET_SELECTED_ITEMS,
    SET_SORTING,
    SET_COLUMN_VISIBILITY,
}

export type TableAction =
    | { type: TableActionType.RESET }
    | { type: TableActionType.SET_PAGINATION; payload: Pagination }
    | { type: TableActionType.SET_PAGE; payload: number }
    | { type: TableActionType.SET_SEARCH_QUERY; payload: string }
    | { type: TableActionType.SET_SELECTED_ITEMS; payload: ID[] }
    | { type: TableActionType.SET_SORTING; payload: SortArg }
    | { type: TableActionType.SET_COLUMN_VISIBILITY; payload: string };
