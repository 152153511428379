import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { getSession } from "next-auth/react";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";

const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
    const session = await getSession();

    return {
        headers: {
            ...headers,
            // @ts-ignore
            Authorization: session?.jwt ? `Bearer ${session?.jwt}` : "",
        },
    };
});

const standardAttributesMerge = {
    fields: {
        attributes: {
            merge: true,
        },
    },
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error) =>
            Sentry.captureException(
                new Error("GraphQL Error", {
                    cause: JSON.stringify(error),
                }),
            ),
        );
    }

    if (networkError) {
        Sentry.captureException(networkError);
    }
});

const client = new ApolloClient({
    link: from([errorLink, authLink, uploadLink]),
    cache: new InMemoryCache({
        typePolicies: {
            BeaconEntity: standardAttributesMerge,
            CaseEntity: standardAttributesMerge,
            CaseItemEntity: standardAttributesMerge,
            CustomerEntity: standardAttributesMerge,
            DetectionEntity: standardAttributesMerge,
            GroupEntity: standardAttributesMerge,
            ReportEntity: standardAttributesMerge,
            ReportResultEntity: standardAttributesMerge,
            UnitEntity: standardAttributesMerge,
            UnitModelEntity: standardAttributesMerge,
            UploadFileEntity: standardAttributesMerge,
            UserGroupEntity: standardAttributesMerge,
            UsersPermissionsUserEntity: standardAttributesMerge,
            Query: {
                fields: {
                    mapObjects: {
                        keyArgs: false,
                        merge(existing = { data: [] }, incoming, { args }) {
                            return {
                                ...incoming,
                                data: args?.pagination?.page > 1 ? [...existing.data, ...incoming.data] : incoming.data,
                            };
                        },
                    },
                    mapObjectGroups: {
                        keyArgs: false,
                        merge(existing = { data: [] }, incoming, { args }) {
                            return {
                                ...incoming,
                                data: args?.pagination?.page > 1 ? [...existing.data, ...incoming.data] : incoming.data,
                            };
                        },
                    },
                },
            },
        },
    }),
});

export default client;
