"use client";

import { FC, PropsWithChildren } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined" && Boolean(process.env.NEXT_PUBLIC_POSTHOG_KEY)) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        capture_pageview: false,
    });
}

export const PHProvider: FC<PropsWithChildren> = ({ children }) => {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
